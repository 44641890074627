import React from "react";
import "../styles/LandingPage.css";
import { Link } from "react-router-dom";
import logo from "../Assets/imgs/logo.webp";
import image from "../Assets/imgs/main-image.webp";
import playNow from "../Assets/imgs/play-now.webp";
import exploreStore from "../Assets/imgs/explore-store.webp";

type Props = {
  onSendOtpPressed: () => void;
  handlePhoneNoChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  phoneNo: string;
  errorMsg: string;
  showLoader: boolean;
};

const Landingpage = (props: Props) => {
  return (
    <div className="landingpage">
      <div className="main-content">
        <div className="logo">
          <img src={logo} width={153} height={71} alt="logo" />
        </div>
        <div className="main-img-wraper">
          <div className="image">
            <img src={image} width={340} height={295} alt="goldstar" />
          </div>
          <div className="form">
            <div className="input-content">
              <div className="price-text">
                Further Only Rs: 12/Day (including SD, VAT, SC)
              </div>
              <div className="text">
                Explore the top mobile games for kids with no ads or in-app
                purchases!
              </div>

              {/* <button onClick={props.onSendOtpPressed}>
                <img src={playNow} alt="playnow" width={230} height={70} />
              </button> */}
              {props.errorMsg ? (
                <>
                  <p className="invalid">{props.errorMsg}</p>
                </>
              ) : (
                <>
                  {props.showLoader ? (
                    <div className="lp-loader">Loading...</div>
                  ) : (
                    <button onClick={props.onSendOtpPressed}>
                      <img
                        src={playNow}
                        alt="playnow"
                        width={230}
                        height={70}
                      />
                    </button>
                  )}
                </>
              )}
            </div>

            <div className="form-text">
              <p>You can cancel your subscription at any time.</p>
            </div>

            <Link to="http://ufone.kidsclub.mobi/store/identify?utm_source=ads_digitology" className="explore-btn">
              <img
                src={exploreStore}
                alt="Explore-Store-Img"
                width={150}
                height={40}
              />
            </Link>

            <div className="condition">
              <a href="/terms" target="_blank">
                Terms & Conditions
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Landingpage;
